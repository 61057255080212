// eslint-disable-next-line import/named
import { getToolTip } from '../../tooltip';
import type { TemplateResult } from 'lit';
import { html, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { when } from 'lit/directives/when.js';
import type { DataBinding } from '../databinding/databinding';
import type { DataTracker } from '../databinding/data-tracker';
import type { InputEventMap, InputType } from './input-type';

//TODO It might be safer to use an Enum for the type (will show dev the options then and limit spelling mistakes)
export function formInput(options: {
  fieldName: string;
  title?: string;
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  type?: InputType;
  readOnly: boolean;
  required: boolean;
  maxLength?: number;
  class?: string;
  toolTip?: string | TemplateResult;
  min?: number;
  max?: number;
  placeholder?: string;
  autocaptitalize?: boolean;
  uppercase?: boolean;
  events?: InputEventMap;
  immediateBindingUpdate?: boolean;
  slotted?: TemplateResult;
  slot?: string;
}): TemplateResult {
  const immediateBindingUpdate = options.immediateBindingUpdate ?? true;
  const fieldName: string = options.fieldName;
  const title: string | undefined = options.title;
  const dataBinding: DataBinding = options.dataBinding;
  const dataTracker: DataTracker = options.dataTracker;
  let type: InputType = options.type ?? 'text';
  const readOnly: boolean = options.readOnly ?? false;
  const required: boolean = options.required ?? false;
  const maxLength: number | undefined = options.maxLength;
  const classes: string | undefined = options.class ?? undefined;
  const toolTip: string | TemplateResult | undefined = options.toolTip ?? undefined;
  const min: number | undefined = options.min ?? undefined;
  const max: number | undefined = options.max ?? undefined;
  const placeholder: string | undefined = options.placeholder ?? undefined;
  const events: InputEventMap | undefined = options.events ?? undefined;
  const autocaptitalize = options.autocaptitalize ?? undefined;
  const uppercase = options.uppercase ?? undefined;
  const slotted = options.slotted ?? undefined;
  const slot = options.slot ?? nothing;
  const dateTimeMax =
    type === 'date'
      ? '9999-12-30'
      : type === 'datetime-local'
        ? '9999-12-30T23:59'
        : type === 'time'
          ? '23:59'
          : undefined;
  let dateTimeMin: string | undefined;
  if (min) {
    dateTimeMin = new Date(min).toISOString();
    if (type === 'date') {
      dateTimeMin = dateTimeMin.split('T')[0];
    } else if (type === 'time') {
      dateTimeMin = dateTimeMin.split('T')[1];
    }
  }
  let value: string | null = null;

  const changeEvent = (e: Event) => {
    if (immediateBindingUpdate) dataTracker.getBinder(fieldName)?.applyChangeToValue();
    options?.events?.change?.(e);
  };
  const blurEvent = (e: Event) => {
    if (immediateBindingUpdate) dataTracker.getBinder(fieldName)?.applyChangeToValue();
    options?.events?.blur?.(e);
  };
  switch (type) {
    case 'area':
      return html` <webmodule-textarea
        class="webmodule-control-left-label ${classes ?? ''}"
        id=${dataBinding.field(fieldName)}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        ?readonly=${readOnly}
        ?filled=${readOnly}
        maxlength="${ifDefined(maxLength)}"
        placeholder="${ifDefined(placeholder)}"
        size="small"
        autocomplete="off"
        slot=${slot}
        @webmodule-blur=${blurEvent}
        @webmodule-change=${changeEvent}
        @webmodule-focus=${events?.focus}
        @webmodule-input=${events?.input}
      >
        ${when(options.title, () => html` <span slot="label"> ${title} ${toolTip ? getToolTip(toolTip) : ''} </span> `)}
      </webmodule-textarea>`;
    case 'checkbox':
      return html` <webmodule-checkbox
        class=${classes ?? ''}
        id=${dataBinding.field(fieldName)}
        ?disabled=${readOnly}
        ?checked=${dataTracker.getObjectValue(fieldName)}
        ?required=${required}
        size="small"
        slot=${slot}
        @webmodule-blur=${blurEvent}
        @webmodule-change=${changeEvent}
        @webmodule-focus=${events?.focus}
        @webmodule-input=${events?.input}
      >
        ${options.title} ${toolTip ? getToolTip(toolTip) : ''}
      </webmodule-checkbox>`;
    case 'switch':
      return html` <webmodule-toggle
        class="webmodule-control-left-label  ${classes ?? ''}"
        id=${dataBinding.field(fieldName)}
        ?checked=${dataTracker.getObjectValue(fieldName)}
        size="small"
        slot=${slot}
        @webmodule-blur=${blurEvent}
        @webmodule-change=${changeEvent}
        @webmodule-focus=${events?.focus}
        @webmodule-input=${events?.input}
      >
        ${when(title, () => html`${options.title} ${toolTip ? getToolTip(toolTip) : ''}`)}
      </webmodule-toggle>`;
    case 'hidden':
      return html`<input
        class=${classes ?? ''}
        type="hidden"
        slot=${slot}
        autocomplete="off"
        id=${dataBinding.field(fieldName)}
        value="${dataTracker.getObjectDisplayValue(fieldName)}"
      />`;
    case 'money':
      return html` <webmodule-input-money
        class="webmodule-control-left-label ${classes ?? ''}"
        id=${dataBinding.field(fieldName)}
        ?readonly=${readOnly}
        ?filled=${readOnly}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        size="small"
        slot=${slot}
        @webmodule-blur=${blurEvent}
        @webmodule-change=${changeEvent}
        @webmodule-focus=${events?.focus}
        @webmodule-input=${events?.input}
        @webmodule-keydown=${events?.keydown}
        @webmodule-keyup=${events?.keyup}
      >
        ${slotted}
        ${when(title, () => html` <span slot="label"> ${title} ${toolTip ? getToolTip(toolTip) : ''} </span> `)}
      </webmodule-input-money>`;
    case 'date':
    case 'datetime-local':
    case 'time':
      value = dataTracker.getObjectDisplayValue(fieldName);
      if (readOnly && value === null) {
        type = 'text';
        value = '';
      }
      return html` <webmodule-input
        class="webmodule-control-left-label ${classes ?? ''}"
        id=${dataBinding.field(fieldName)}
        min="${ifDefined(dateTimeMin)}"
        max="${ifDefined(dateTimeMax)}"
        slot=${slot}
        type=${type}
        ?filled=${readOnly}
        ?readonly=${readOnly}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        size="small"
        autocomplete="off"
        @webmodule-blur=${blurEvent}
        @webmodule-change=${changeEvent}
        @webmodule-focus=${events?.focus}
        @webmodule-input=${events?.input}
        @webmodule-keydown=${events?.keydown}
        @webmodule-keyup=${events?.keyup}
        >${slotted}
        ${when(title, () => html` <span slot="label"> ${title} ${toolTip ? getToolTip(toolTip) : ''} </span> `)}
      </webmodule-input>`;
    default:
      return html` <webmodule-input
        class="webmodule-control-left-label ${classes ?? ''}"
        id=${dataBinding.field(fieldName)}
        maxlength="${ifDefined(maxLength)}"
        min="${ifDefined(min)}"
        max="${ifDefined(max)}"
        slot=${slot}
        type=${type}
        ?readonly=${readOnly}
        ?filled=${readOnly}
        .value=${dataTracker.getObjectDisplayValue(fieldName)}
        ?required=${required}
        size="small"
        autocomplete="off"
        @webmodule-blur=${blurEvent}
        @webmodule-change=${changeEvent}
        @webmodule-focus=${events?.focus}
        @webmodule-input=${events?.input}
        @webmodule-keydown=${events?.keydown}
        @webmodule-keyup=${events?.keyup}
        autocapitalize=${ifDefined(autocaptitalize)}
        uppercase=${ifDefined(uppercase)}
        >${slotted}
        ${when(title, () => html` <span slot="label"> ${title} ${toolTip ? getToolTip(toolTip) : ''} </span> `)}
      </webmodule-input>`;
  }
}
