import { ButtonEventMap } from './input-type';
import { html, nothing, TemplateResult } from 'lit';
import { WebmoduleButtonSize, WebmoduleButtonVariant } from '../../../components/src/components/button/button';

export type ButtonSlot = 'suffix' | 'prefix';

export interface ButtonOptions {
  events?: ButtonEventMap;
  readonly?: boolean;
  slot?: ButtonSlot;
  class?: string;
  size?: WebmoduleButtonSize;
}

interface ButtonOptionsInternal {
  events?: ButtonEventMap;
  readonly?: boolean;
  slot?: ButtonSlot;
  variant?: WebmoduleButtonVariant;
  size?: WebmoduleButtonSize;
  class?: string;
}

export class FormInputAssistantBase {
  constructor(forceReadonly: boolean | (() => boolean) = false) {
    this._forceReadonly = typeof forceReadonly === 'function' ? forceReadonly : () => forceReadonly;
  }

  private _forceReadonly: () => boolean;

  get forceReadonly(): boolean {
    return this._forceReadonly();
  }

  public clickableIcon(options: {
    events: ButtonEventMap;
    library: string;
    name: string;
    class?: string;
    slot?: string;
  }) {
    return html` <webmodule-icon-button
      class="clickable ${options.class ?? ''}"
      @click=${options.events.click}
      slot=${options.slot ?? nothing}
      library=${options.library}
      name=${options.name}
    ></webmodule-icon-button>`;
  }

  public buttonPrimary(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'primary' });
  }

  public buttonDefault(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'default' });
  }

  public buttonDanger(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'danger' });
  }

  public buttonWarning(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'warning' });
  }

  public buttonSuccess(caption: string | TemplateResult, options: ButtonOptions) {
    return this.buttonInternal(caption, { ...options, variant: 'success' });
  }

  protected buttonInternal(caption: string | TemplateResult, options?: ButtonOptionsInternal) {
    return html` <webmodule-button
      ?disabled=${options?.readonly ?? false}
      .variant=${options?.variant ?? 'default'}
      @click=${options?.events?.click}
      slot=${options?.slot ?? nothing}
      size=${options?.size ?? nothing}
      class=${options?.class ?? nothing}
      >${caption}
    </webmodule-button>`;
  }
}
