// noinspection HtmlUnknownAttribute
import { customElement, property, query, state } from 'lit/decorators.js';
// eslint-disable-next-line import/named
import { classMap } from 'lit/directives/class-map.js';
import { fileToBase64 } from './blob-converters';
import { getInternalId } from './ui/databinding/databinding';
import { getToolTip } from './tooltip';
import type { TemplateResult } from 'lit';
import { html, LitElement } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { information } from './ui/modal-option';
import { isEmptyOrSpace } from './ui/string-helper-functions';
import { money4dpToStr, moneyToStr, strToMoney } from './currency-formatter';
import { tlang } from './language/lang';

export class InputView extends LitElement {
  createRenderRoot() {
    return this;
  }

  render() {
    return html``;
  }

  attributeTrue(name: string) {
    if (!this.hasAttribute(name)) return false;
    else {
      const attr = this.getAttribute(name);
      return attr == undefined || attr !== 'false';
    }
  }
}

@customElement('bs-form-money')
export class FormInputMoneyView extends InputView {
  @property({ type: String })
  type = '';
  @property({ type: Boolean })
  required = false;
  @property({ type: Boolean })
  readonly = false;
  @property({ type: String })
  maxlength: string | null = null;
  @property({ type: Number })
  dp = 2;
  @property({ type: String })
  toolTip: string | null = null;
  @query('input')
  protected input?: HTMLInputElement;
  private _internalValue = '';
  private _firstRenderAfterFocus = false;

  @property({ type: String })
  get value(): string {
    if (isEmptyOrSpace(this._internalValue)) return '';
    return strToMoney(this._internalValue, this.dp).toFixed(this.dp);
  }

  set value(v: string | null) {
    if (!this.setInternalValue(v)) return;
    const input = this.input;
    if (input && !this._internalFocused) input.value = this.getCurrentDisplayValue();
  }

  get realValue(): number | null {
    if (isEmptyOrSpace(this._internalValue)) return null;

    const v = strToMoney(this._internalValue);
    if (Number.isNaN(v)) return 0;
    else return v;
  }

  @state()
  private _internalFocused = false;

  protected get internalFocused() {
    return this._internalFocused;
  }

  protected set internalFocused(value) {
    this._internalFocused = value;

    this._firstRenderAfterFocus = value;
  }

  updated(changedProperties) {
    changedProperties.forEach((_oldValue, propName) => {
      if (propName === '_internalFocused' && this.internalFocused) this.input?.select();
    });
  }

  render() {
    const toolTip = this.toolTip ? getToolTip(this.toolTip) : html``;

    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const classes = `form-control fw-bold ${!this.internalFocused && this.realValue && this.realValue < 0 ? 'text-danger' : ''}`;
    let val = this.internalFocused ? this.value : this.displayValue(this.value);

    //    if (elem) {
    //this means we have already rendered before, and we might be focused
    //    if (elem === document.activeElement && !this.readonly) val = elem.value;
    //}
    const input = this.input;

    if (!this._firstRenderAfterFocus && this.internalFocused && input) {
      val = input.value;
    }
    const labelTemplate = this.dataset.label
      ? html`<label for=${this.dataset.id} class="form-col-label">${this.dataset.label}:${reqSpan} ${toolTip}</label>`
      : html``;

    return html`
      <div class="row mb-2 align-items-center form-col-item">
        ${labelTemplate}
        <div class="form-col-input">
          <input
            class="input-money ${classes}"
            autocomplete="off"
            @input=${this.inputEvent}
            @blur=${this.blurEvent}
            @focus=${this.focusEvent}
            @keyup=${this.onkeyup}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .value=${val}
            ?required=${this.required}
            ?readonly=${this.readonly}
            maxlength="${ifDefined(this.maxlength)}"
          />
        </div>
      </div>
    `;
  }

  displayValue(_internalValue: string): string {
    if (isEmptyOrSpace(_internalValue)) return '';
    const val = strToMoney(_internalValue, this.dp);
    if (this.dp == 4) return money4dpToStr(val);
    else return moneyToStr(val);
  }

  private getCurrentDisplayValue(): string {
    return this.internalFocused ? this.value : this.displayValue(this.value);
  }

  private setInternalValue(v: string | null) {
    const oldVal = this._internalValue;
    let newVal = '';
    if (v === null || v === 'null') {
      newVal = '';
    } else {
      newVal = strToMoney(v, this.dp).toFixed(this.dp);
    }
    if (newVal === oldVal) return false;
    this._internalValue = newVal;
    return true;
  }

  private blurEvent = (_e: Event) => {
    this.internalFocused = false;
  };

  private focusEvent = (_e: Event) => {
    this.internalFocused = true;
  };

  private inputEvent = (e: Event) => {
    const input = e.currentTarget as HTMLInputElement;
    const v = strToMoney(input.value, this.dp);
    if (Number.isNaN(v)) this.setInternalValue(null);
    else this.setInternalValue(input.value);
  };
}

@customElement('bs-form-input')
export class FormInputInputView extends InputView {
  type = '';
  value = '';
  required = false;
  readonly = false;
  maxlength: string | null = null;
  min: string | null = null;
  max: string | null = null;
  pattern: string | null = null;
  toolTip: string | null = null;

  static get properties() {
    return {
      'data-label': { type: String },
      type: { type: String },
      value: { type: String },
      required: { type: Boolean },
      rows: { type: Number },
      readonly: { type: Boolean },
      maxlength: { type: String },
      min: { type: String },
      max: { type: String },
      pattern: { type: String },
      toolTip: { type: String }
    };
  }

  render() {
    const focusEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      if (input.type === 'text' || input.type === 'number') input.select();
    };
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const toolTip = this.toolTip ? getToolTip(this.toolTip) : html``;
    const step = this.type == 'number' ? 'any' : undefined;
    let classes = 'form-control';
    if (this.type.toLowerCase() == 'number') classes += '';
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.id} class="form-col-label">${this.dataset.label}:${reqSpan} ${toolTip}</label>
        <div class="form-col-input">
          <input
            @oninput=${this.oninput}
            @blur=${this.onblur}
            @keyup=${this.onkeyup}
            class=${classes}
            @focus=${focusEvent}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .type=${this.type}
            .value=${this.value}
            ?required=${this.required}
            ?readonly=${this.readonly}
            maxlength="${ifDefined(this.maxlength)}"
            min="${ifDefined(this.min)}"
            max="${ifDefined(this.max)}"
            pattern="${ifDefined(this.pattern)}"
            step="${ifDefined(step)}"
          />
        </div>
      </div>
    `;
  }
}

@customElement('bs-form-input-no-label')
export class FormInputInputNoLabelView extends InputView {
  type = '';
  value = '';
  required = false;
  readonly = false;
  maxlength: string | null = null;
  min: string | null = null;
  max: string | null = null;
  step: string | null = null;

  @query('input') _input!: HTMLInputElement;

  static get properties() {
    return {
      type: { type: String },
      value: { type: String },
      required: { type: Boolean },
      readonly: { type: Boolean },
      maxlength: { type: String },
      min: { type: String },
      max: { type: String },
      step: { type: String }
    };
  }

  render() {
    const focusEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      if (input.type === 'text' || input.type === 'number') input.select();
    };

    let classes = 'form-control';
    if (this.type.toLowerCase() == 'number') classes += '';
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <div class="form-col-input">
          <input
            @oninput=${this.oninput}
            @blur=${this._dispatchBlur}
            @keyup=${this.onkeyup}
            class=${classes}
            @focus=${focusEvent}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .type=${this.type}
            .value=${this.value}
            ?required=${this.required}
            ?readonly=${this.readonly}
            maxlength="${ifDefined(this.maxlength)}"
            min="${ifDefined(this.min)}"
            max="${ifDefined(this.max)}"
            name="${ifDefined(this.dataset.id)}"
            step=${ifDefined(this.dataset.step)}
          />
        </div>
      </div>
    `;
  }

  private _dispatchBlur() {
    const id = this._input.id;
    const value = this._input.value.trim();

    const options = {
      detail: { id, value },
      bubbles: true,
      composed: true
    };

    this.dispatchEvent(new CustomEvent('bs-form-input-blur', options));
  }
}

@customElement('bs-form-display')
export class FormInputDisplayView extends InputView {
  value = '';

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String }
    };
  }

  render() {
    const classes = 'form-control-plaintext';

    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.id} class="form-col-label">${this.dataset.label}:</label>
        <div class="form-col-input">
          <input
            class=${classes}
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            type="text"
            readonly
            .value=${this.value}
          />
        </div>
      </div>
    `;
  }
}

@customElement('bs-form-checkbox')
export class FormInputCheckboxView extends InputView {
  value = '';
  required = false;
  readonly = false;
  checked = false;
  display: 'switch' | 'checkbox' | 'inline-checkbox' = 'checkbox';

  static get properties() {
    return {
      'data-label': { type: String },
      checked: { type: Boolean },
      required: { type: Boolean },
      readonly: { type: Boolean },
      display: { type: String }
    };
  }

  toggleCheck() {
    this.checked = !this.checked;

    this.dispatchEvent(
      new CustomEvent('checkbox-changed', {
        detail: this.checked
      })
    );
  }

  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const isCheckbox = this.display === 'checkbox';
    const inputHtml = html`
      <input
        @oninput=${this.oninput}
        @blur=${this.onblur}
        class="form-check-input"
        @click=${this.toggleCheck}
        .id=${ifDefined(this.dataset.id)}
        type="checkbox"
        ?required=${this.required}
        ?readonly=${this.readonly}
        ?disabled=${this.readonly}
        ?checked=${this.checked}
      />
    `;

    if (isCheckbox) {
      return html`
        <div class="row mb-2 align-items-center form-col-item">
          <label for=${this.dataset.id} class="form-col-label">${this.dataset.label} ${reqSpan}:</label>
          <div class="form-col-input">${inputHtml}</div>
        </div>
      `;
    } else {
      return html`
        <div class="row mb-2 align-items-center form-col-item">
          <div
            class="form-check ${classMap({
              'form-check-inline': this.display === 'inline-checkbox',
              'form-switch': this.display === 'switch'
            })}"
          >
            ${inputHtml}
            <label for=${ifDefined(this.dataset.id)} class="form-check-label">${this.dataset.label} ${reqSpan}</label>
          </div>
        </div>
      `;
    }
  }
}

@customElement('bs-form-toggle')
export class FormInputToggleView extends FormInputCheckboxView {
  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    return html`
      <div
        class="form-check ${classMap({
          'form-check-inline': this.display === 'inline-checkbox',
          'form-switch': this.display === 'switch'
        })}"
      >
        <input
          @oninput=${this.oninput}
          @blur=${this.onblur}
          class="form-check-input"
          @click=${this.toggleCheck}
          .id=${ifDefined(this.dataset.id)}
          type="checkbox"
          ?required=${this.required}
          ?readonly=${this.readonly}
          ?disabled=${this.readonly}
          ?checked=${this.checked}
        />

        <label for=${ifDefined(this.dataset.id)} class="form-check-label">${this.dataset.label} ${reqSpan}</label>
      </div>
    `;
  }
}

@customElement('bs-form-picker')
export class FormInputPickerView extends InputView {
  valuedisplay = '';
  valuedata: string | null = null;
  required = false;
  readonly = false;

  static get properties() {
    return {
      'data-label': { type: String },
      valuedisplay: { type: String, reflect: true },
      valuedata: { type: String, reflect: true },
      required: { type: Boolean },
      readonly: { type: Boolean }
    };
  }

  render() {
    const keyDownEvent = (e: Event) => {
      const kEvent = e as KeyboardEvent;
      if (kEvent.code === 'Enter') {
        e.preventDefault();
        this.clicked();
      }
      if (kEvent.code !== 'Tab') e.preventDefault();
    };
    const clickEvent = () => this.clicked();
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.dataset.id} class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
        <div class="col-10 form-col-input">
          <input
            class="form-control form-select"
            .id=${ifDefined(this.dataset.id)}
            type="text"
            .value=${this.valuedisplay}
            data-value=${ifDefined(this.valuedata)}
            data-picker="Yes"
            @click=${clickEvent}
            ?required=${this.required}
            ?readonly=${this.readonly}
            @keydown=${keyDownEvent}
          />
        </div>
      </div>
    `;
  }

  private clicked() {
    if (!this.readonly) this.dispatchEvent(new CustomEvent('picker-clicked'));
  }
}

@customElement('bs-paginator')
export class Paginator extends InputView {
  index = 1;
  count = 1;

  static get properties() {
    return {
      index: { type: String },
      count: { type: String }
    };
  }

  render() {
    let currentPage = this.index;
    const pageCount = this.count;

    const visiblePages = 11;
    const pageDeductor = 5;
    let firstDisplayPage = currentPage - pageDeductor;
    if (firstDisplayPage < 1) firstDisplayPage = 1;
    let lastDisplayPage = firstDisplayPage + visiblePages - 1;
    if (lastDisplayPage > pageCount) lastDisplayPage = pageCount;

    if (lastDisplayPage === pageCount && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      firstDisplayPage = Math.max(1, lastDisplayPage - visiblePages + 1);
    if (firstDisplayPage === 1 && lastDisplayPage - firstDisplayPage + 1 < visiblePages)
      lastDisplayPage = Math.min(pageCount, firstDisplayPage + visiblePages - 1);

    const includeSkipToFirst = firstDisplayPage > 1;
    const includeSkipToLast = lastDisplayPage < pageCount - 1;

    const clickEvent = (e: Event) => {
      e.preventDefault();
      const strPage = (e.target as HTMLUListElement).textContent?.toLowerCase();
      if (strPage === 'prev') currentPage--;
      else if (strPage === 'next') currentPage++;
      else if (strPage === 'last') currentPage = pageCount;
      else if (strPage === 'first') currentPage = 1;
      else currentPage = parseInt(strPage ?? '1');

      //handle accidental overflows
      currentPage = Math.min(pageCount, Math.max(1, currentPage));
      const event = new CustomEvent('page-change', {
        detail: {
          index: currentPage - 1
        }
      });
      this.dispatchEvent(event);
    };

    const pages = (): TemplateResult[] => {
      const pageResult: TemplateResult[] = [];
      for (let i = firstDisplayPage; i <= lastDisplayPage; i++) {
        if (i === currentPage) {
          pageResult.push(
            html` <li class="page-item active">
              <span class="page-link">${i}</span>
            </li>`
          );
        } else {
          pageResult.push(
            html` <li class="page-item">
              <a class="page-link" href="#" @click=${clickEvent}>${i}</a>
            </li>`
          );
        }
      }
      return pageResult;
    };

    const skipFirstClass = `page-item ${!includeSkipToFirst ? 'disabled' : ''}`;
    const currentPageClass = `page-item ${currentPage === 1 ? 'disabled' : ''}`;
    const lastPageClass = `page-item ${currentPage === pageCount ? 'disabled' : ''}`;
    const skipToLastClass = `page-item ${!includeSkipToLast ? 'disabled' : ''}`;
    return html` <nav aria-label="Page navigation">
      <ul class="pagination pagination-sm justify-content-center">
        <li class=${skipFirstClass}>
          <a class="page-link" href="#" @click=${clickEvent}>First</a>
        </li>
        <li class=${currentPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Prev</a>
        </li>
        ${pages()}
        <li class=${lastPageClass}>
          <a class="page-link" href="#" rel="prev" @click=${clickEvent}>Next</a>
        </li>
        <li class=${skipToLastClass}>
          <a class="page-link" href="#" @click=${clickEvent}>Last</a>
        </li>
      </ul>
    </nav>`;
  }
}

@customElement('bs-form-area')
export class FormInputAreaView extends InputView {
  rows = '';
  value = '';
  required = false;
  readonly = false;
  maxlength: string | null = null;
  toolTip: string | null = null;

  static get properties() {
    return {
      'data-label': { type: String },
      type: { type: String },
      value: { type: String },
      required: { type: Boolean },
      readonly: { type: Boolean },
      maxlength: { type: Number },
      toolTip: { type: String }
    };
  }

  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;

    const toolTip = this.toolTip ? getToolTip(this.toolTip) : html``;

    const changeEvent = (e: Event) => {
      const input = e.currentTarget as HTMLInputElement;
      this.value = input.value;
      this.dispatchEvent(
        new CustomEvent('wm-text-changed', {
          detail: this.value
        })
      );
    };

    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label .for=${ifDefined(this.dataset.id)} class="col-2 form-col-label"
          >${this.dataset.label}:${reqSpan} ${toolTip}</label
        >
        <div class="col-10 form-col-input">
          <textarea
            @oninput=${this.oninput}
            @blur=${this.onblur}
            @keyup=${this.onkeyup}
            @change=${changeEvent}
            class="form-control"
            .id=${ifDefined(this.dataset.id)}
            .placeholder=${ifDefined(this.dataset.placeholder)}
            .rows=${this.rows}
            .value=${this.value}
            ?required=${this.required}
            maxlength=${ifDefined(this.maxlength)}
            ?readonly=${this.readonly}
          >
          </textarea>
        </div>
      </div>
    `;
  }
}

export interface FormInputSelectValue {
  text: string;
  value: string;
  disabled: boolean;
}

export function toFormInputSelectOption(text: string, value: string, disabled: boolean = false): FormInputSelectValue {
  return {
    text,
    value,
    disabled
  };
}

export function dlSelectValues(input: any[], convert: (x: any) => FormInputSelectValue): string {
  return JSON.stringify(input.map(x => convert(x)));
}

export function dlSelectValuesArray(input: any[], convert: (x: any) => FormInputSelectValue): FormInputSelectValue[] {
  return input.map(x => convert(x));
}

export function getEnumKeys(e: any, hideValues?: number[]): number[] {
  return Object.keys(e)
    .filter(o => {
      const value = parseInt(o);
      if (isNaN(value)) return false;
      return !(hideValues && hideValues.includes(value));
    })
    .map(x => parseInt(x));
}

export function getEnum<E>(e: E, value) {
  return getEnumKeys(e)[value];
}

export function dlEnumSelectValues<E>(e: E, hideValues?: number[], map?: EnumValueToString): string {
  return dlSelectValues(getEnumKeys(e, hideValues), (key: number) => {
    const text = map?.(key) ?? e[key];
    return { text: text, value: key.toString(), disabled: false };
  });
}

export function dlEnumSelectValuesArray<E>(
  e: E,
  hideValues?: number[],
  map?: EnumValueToString
): FormInputSelectValue[] {
  return dlSelectValuesArray(getEnumKeys(e, hideValues), key => {
    const text = map?.(key) ?? e[key];
    return { text: text, value: key.toString(), disabled: false };
  });
}

/**
 *
 * @param input an array of objects
 * @param convert a converter to turn an object from input into a FormInputSelect
 * @returns a stringified json object to pass as a parameter
 *
 * * @example
 *
 * ```ts
 *
 * let test = [ {name:"bob" , id:1, age:34},{name:"tom" ,age:27, id:2} ]
 *
 * const options = MapArrayToFormInputSelectValue(test,(obj:any)=> {text:obj.name, value:obj.id} )
 * let template = html`<bs-form-select options=${options} />`
 *
 * ```
 *
 */
export type EnumValueToString = (value: number) => string;

export function MapArrayToFormInputSelectValue<ObjectType>(
  input: ObjectType[],
  convert: (x: ObjectType) => FormInputSelectValue
): FormInputSelectValue[] {
  return input.map(x => convert(x));
}

/**
 *
 * @param typeValue the enumerated type to convert to a picker selection
 *
 * @param hideValues
 * if false, we exlude the 0
 * @param map
 * @returns a string containing jsonarray of data to pass to the options attribute for <bs-form-select>
 *
 * @example
 *
 * ```ts
 * enum Test
 * {
 *    all = 0,
 *    one = 1,
 *    two = 2
 * }
 * const options = ConvertEnumToFormSelectOptions(Test, true)
 * let template = html`<bs-form-select options=${options} />`
 *
 * ```
 *
 */
export function ConvertEnumToFormSelectOptions<EnumeratedType>(
  typeValue: EnumeratedType,
  hideValues?: number[],
  map?: EnumValueToString
) {
  return dlEnumSelectValues(typeValue, hideValues, map);
}

export function ConvertEnumToFormSelectOptionsArray<EnumeratedType>(
  typeValue: EnumeratedType,
  hideValues?: number[],
  map?: EnumValueToString
): FormInputSelectValue[] {
  return dlEnumSelectValuesArray(typeValue, hideValues, map);
}

@customElement('bs-form-select')
export class FormInputSelectView extends InputView {
  value?: string;
  options?: string | FormInputSelectValue[];
  required?: boolean = false;

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String },
      options: { type: Object },
      required: { type: Boolean }
    };
  }

  render() {
    const internalOptions = this.options ?? [];

    const optionsArray: FormInputSelectValue[] =
      typeof internalOptions === 'string' ? JSON.parse(internalOptions) : internalOptions;

    const options: HTMLOptionElement[] = [];

    if (this.dataset.placeholder) {
      const placeholder = new Option(this.dataset.placeholder, undefined);

      options.push(placeholder);
    }

    optionsArray.forEach(x =>
      options.push(new Option(x.text, x.value, undefined, x.value.toString() == this.value?.toString()))
    );

    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    const changeEvent = e => {
      this.value = e.target.value;
      this.dispatchEvent(
        new CustomEvent('wm-select-changed', {
          detail: this.value
        })
      );
    };
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label for=${this.id} class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
        <div class="col-10 form-col-input">
          <select
            class="form-select"
            ?readonly=${this.attributeTrue('readonly')}
            ?disabled=${this.attributeTrue('disabled')}
            @change=${changeEvent}
            .value=${this.value}
            .id=${ifDefined(this.dataset.id)}
            aria-label=${this.dataset.label}
            ?required=${this.required}
          >
            ${options}
          </select>
        </div>
      </div>
    `;
  }
}

@customElement('bs-form-radio-group')
export class FormInputRadioGroupView extends InputView {
  required = false;
  readonly = false;
  options = '';
  value = '';
  horizontal = true;

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String, reflect: true },
      required: { type: Boolean },
      readonly: { type: Boolean },
      options: { type: String },
      horizontal: { type: Boolean }
    };
  }

  toggleCheck(selectedValue: string) {
    this.value = selectedValue;

    this.dispatchEvent(
      new CustomEvent('radio-changed', {
        detail: selectedValue
      })
    );
  }

  render() {
    let optionsArray: FormInputSelectValue[] = [];
    try {
      optionsArray = JSON.parse(this.options ?? '[]');
    } catch {
      throw new Error(`Invalid options: "${this.options}" on ${this.dataset.id}`);
    }
    const readonly = this.readonly;

    const optionsTemplates = optionsArray.map((option, index) => {
      const checked = option.value == this.value;
      const inputId = this.dataset.id ?? getInternalId();
      const isLine = this.horizontal ? 'form-check-inline' : '';

      const clickEvent = readonly
        ? (e: Event) => {
            e.preventDefault();
          }
        : () => this.toggleCheck(option.value);
      const forId = 'rbg-' + inputId + index;
      return html`
        <div class="form-check ${isLine}">
          <input
            class="form-check-input"
            type="radio"
            value=${option.value}
            name=${this.dataset.id}
            id="${' rbg-' + inputId + index}"
            ?checked=${checked}
            ?disabled=${readonly || option.disabled}
            ?readonly=${readonly}
            @click=${clickEvent}
          />
          <label class="form-check-label" for=${forId}> ${option.text} </label>
        </div>
      `;
    });
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;
    return html`
      <div class="row mb-2 align-items-center form-col-item">
        <label class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
        <div class="col-10 form-col-input">${optionsTemplates}</div>
      </div>
    `;
  }
}

@customElement('bs-form-image-upload')
export class FormInputImageFileUploadView extends InputView {
  required?: boolean = false;
  @query('input')
  fileElement?: HTMLInputElement;
  @property() readonly = false;
  @property() defaultImage =
    'https://webmodule-softtech.azureedge.net/v6config/site/assets/images/transparent-pixel.gif';
  @property() showFileInput = true;
  @property() isThumbnail = false;
  @property() resize = false;
  @property() resizeHeight = 400;
  @property() resizeWidth = 400;
  @property() displayType = 0;
  @property() clickCaption = 'upload image';
  @property() acceptedFiles = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'];
  private fileValue?: Blob;

  static get properties() {
    return {
      'data-label': { type: String },
      value: { type: String },
      required: { type: Boolean }
    };
  }

  private _fileExtension?: string;

  get fileExtension() {
    return this._fileExtension;
  }

  @state() private _value: string | undefined;

  public get value(): string | undefined {
    return this._value;
  }

  public set value(value: string | undefined) {
    this._value = value;
  }

  get isValueChanged(): boolean {
    if (this.fileElement?.files) return this.fileElement?.files.length > 0;

    return false;
  }

  get isImage() {
    return (
      (this.acceptedFiles.some(x => x.startsWith('image/')) && isEmptyOrSpace(this.value)) ||
      (!isEmptyOrSpace(this.value) && isImageLink(this.value))
    );
  }

  async ClearFiles() {
    if (this.fileElement?.files) {
      this.fileElement.files = null;
      this.fileElement.value = '';
    }
  }

  async base64Value() {
    if (this.fileValue) {
      return await fileToBase64(this.fileValue);
    }
    return '';
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('dragover', this.eventDragOver);
    this.addEventListener('drop', this.eventDrop);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('dragover', this.eventDragOver);
    this.removeEventListener('drop', this.eventDrop);
  }

  render() {
    const reqSpan = this.required ? html`<span class="text-danger">*</span>` : html``;

    const changeEvent = e => {
      this.onPreview(e);
      const newEvent = new CustomEvent('webmodule-change', {
        detail: e,
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(newEvent);
    };

    const eventPickImage = (e: Event) => {
      e.stopImmediatePropagation();
      if (this.readonly) return;
      this.fileElement?.click();
    };
    const imgId = `${this.dataset.id}-img`;

    const fileInputTemplate = html`<input
      id="${this.dataset.id}"
      class="form-control "
      type="file"
      accept=${this.acceptedFiles.join(',')}
      @change=${changeEvent}
      value=${this.value}
      ?disabled=${this.readonly}
      ?readonly=${this.readonly}
      ?hidden=${!this.showFileInput}
    />`;

    //this is the default header as used in the branding where you can pick and image
    //or drag and drop an image
    const header = this.showFileInput
      ? html` <div class="row mb-3 align-items-center form-col-item image-upload-field">
          <label for=${this.dataset.id} class="col-2 form-col-label">${this.dataset.label}:${reqSpan}</label>
          <div class="col-10 form-col-input">${fileInputTemplate}</div>
        </div>`
      : fileInputTemplate; //this is a hidden input

    const imgSrc = isEmptyOrSpace(this.value) ? this.defaultImage : this.value;

    const isDefaultImg = isEmptyOrSpace(this.value);

    const imageTemplate = !this.isThumbnail
      ? //this is the original template as used on the branding page
        html`
          <div @click=${eventPickImage} class="row mb-3 align-items-center form-col-item image-upload-image">
            <div class="col-10 offset-0 offset-sm-2 offset-md-3 offset-xl-2 form-col-input">
              <div class="image-upload-wrapper">
                <img src=${imgSrc} id=${imgId} class="img-fluid " alt="Thumbnail for the uploaded file" />
              </div>
            </div>
          </div>
        `
      : //this is a new template for thumbnail
        html`
          <div class="single-file-drop-container" @click=${eventPickImage}>
            <img
              src=${imgSrc}
              id=${imgId}
              class="img-fluid ${isDefaultImg ? 'img-default-upload' : ''}"
              alt="Custom Request Thumbnail"
            />
            <h2>Thumbnail</h2>
            <div class="image-upload-icon">
              <i class="fa fa-solid fa-cloud-arrow-up"></i>
            </div>
            <div class="image-upload-button upload-button">${this.clickCaption}</div>
          </div>
        `;

    return html` ${header} ${imageTemplate} `;
  }

  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
          return;
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext('2d');
        if (!context) {
          resolve(file);
          return;
        }

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve as BlobCallback, file.type);
      };
      image.onerror = reject;
    });
  }

  protected eventDragOver = (e: Event) => {
    e.preventDefault();
  };

  protected eventDrop = async (e: DragEvent) => {
    e.preventDefault();
    if (this.readonly) return;

    if (this.fileElement && e.dataTransfer) {
      this.fileElement.files = e.dataTransfer.files;
      await this.onPreview(undefined);
    }
  };

  /**
   * Used to display a preview of a selected image file
   * @param event
   */
  private async onPreview(event?: Event) {
    const target = event?.target as HTMLInputElement;
    const file = target?.files?.item(0) ?? this.fileElement?.files?.item(0);
    if (file) {
      const validImageTypes = this.acceptedFiles;
      if (validImageTypes.includes(file.type)) {
        this._fileExtension = file.name.substring(file.name.lastIndexOf('.'));
        this.fileValue =
          this.resize && isImageLink(file.name)
            ? await this.resizeImage(file, this.resizeWidth, this.resizeHeight)
            : (file as Blob);
        this.value = window.URL.createObjectURL(this.fileValue);
      } else await information(tlang`Currently supported file types are ${this.acceptedFiles.join(', ')}`);
    }
  }
}

function isImageLink(value: string | undefined): boolean {
  if (!value) return false;
  const extension = value.substring(value.lastIndexOf('.')).toLowerCase();

  return extension === '.jpg' || extension === '.svg' || extension === '.png' || extension === '.gif';
}
