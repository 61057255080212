import {
  AskConfirmation,
  buttonsContinueCancel,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../webmodule-common/other/ui/modal-confirmation';
import { asMarkdown } from '../../../webmodule-common/other/general/markdown';
import { awaitableTemplate } from '../../../webmodule-common/other/ui/template-processor';
import { constructAsync } from '../../../webmodule-common/other/async-constructor';
import { currentQuoteSupplierProvider } from '../data/quoteSupplierProvider';
import { customElement } from 'lit/decorators.js';
import { DataCacheGeneric } from '../../../webmodule-common/cache/generic-data-cache';
import { DataEntryOwner } from '../../../webmodule-common/other/ui/DataEntryOwner';
import { DataEntryPageControlView, ModalViewBase } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { DealerReport } from '../../api/dealer-api-interface-clientagent';
import { DevelopmentError, showDevelopmentError } from '../../../webmodule-common/other/development-error';
import { disposeOf } from '../../../webmodule-common/other/dispose';
import { emptyGuid } from '../../../webmodule-common/other/api/guid';
import {
  EventGetReference,
  EventSnippet,
  EventTemplate,
  NullPromise,
  PromiseTemplate,
  Snippet
} from '../../../webmodule-common/other/ui/events';
import { FileMap } from '../../../webmodule-common/other/api/api-type-base-interface';
import {
  fireQuickErrorToast,
  fireQuickInformationToast,
  fireQuickSuccessToast,
  fireQuickWarningToast
} from '../../../webmodule-common/other/toast-away';
import { FreehandLineItemView } from './freehand-line-item-view';
import { getApiFactory } from '../../api/api-injector';
import { html, TemplateResult } from 'lit';
import { information } from '../../../webmodule-common/other/ui/modal-option';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { isContentTypeSSI, isFrame, isSpecialItem } from '../data/quote-helper-functions';
import { isDebugMode } from '../../../webmodule-common/other/debug';
import { isMultiSupplier } from '../quote-service';
import { isSupplierAgent } from '../../../webmodule-common/other/currentuser-claims';
import { IUserQuoteStateEngine } from '../data/quote-state-engine';
import { lang, tlang } from '../../../webmodule-common/other/language/lang';
import {
  MenuIconAction,
  MenuIconEventList,
  MenuIconOption,
  PageControl,
  PageControlDeleteTabEventData,
  PageControlOptions,
  PageManager
} from '../../../webmodule-common/other/ui/page-control';
import { QuoteApi } from '../../api/quote-api';
import { QuoteContainer, QuoteContainerManager } from '../data/quote-container';
import { QuoteDetailView, QuoteDetailViewOptions } from './quote-detail-view';
import { quoteItemAction } from '../data/events';
import { QuoteItemContainer } from '../data/quote-item-container';
import { quoteItemContentType } from '../data/default-quote-item-content-type';
import { QuoteItemsView as QuoteItemsView, QuoteItemsViewOptions } from './quote-items-view';
import { QuoteItemView, QuoteItemViewOptions } from './quote-item-view';
import { QuoteState } from '../../api/dealer-api-interface-quote';
import { QuoteSupplierDefaultsView, QuoteSupplierDefaultsViewOptions } from './quote-supplier-defaults.view';
import { QuoteSupplierGlassView, QuoteSupplierGlassViewOptions } from './quote-supplier-glass-view';
import { ResultGetSupplierQuoteSettings } from '../../api/dealer-api-interface-franchisee';
import { ShippingLineItemView } from './shipping-line-item-view';
import { showError } from '../../../webmodule-common/other/ui/show-error';
import { SpecialLineItemView } from './special-line-item-view';
import { SSEQuote } from '../data/sse-quote';
import { SupplierApi } from '../../api/supplier-api';
import { supplierQuoteItemContentType } from '../data/supplier-quote-item-content-type';
import { waitPatientlyLoading } from '../../../webmodule-common/other/ui/modal-loading';
import { WMEventSource } from '../../api/event-source';

export interface QuoteViewOptions {
  quoteContainerManager: QuoteContainerManager;
  title: EventSnippet;
  customerCache: DataCacheGeneric;
  userCache: DataCacheGeneric;
  ownerId: EventGetReference;
}

export interface ButtonCaptionProvider {
  caption: string;
}

export interface QuoteViewChildFactory {
  getDetailView(options: QuoteDetailViewOptions): QuoteDetailView;

  getItemsView(options: QuoteItemsViewOptions): QuoteItemsView;

  getSupplierDefaultsView(options: QuoteSupplierDefaultsViewOptions): QuoteSupplierDefaultsView;

  getSupplierGlassView(options: QuoteSupplierGlassViewOptions): QuoteSupplierGlassView;

  getSupplierQuoteItemView(
    contentType: number,
    options: QuoteItemViewOptions,
    supplierSelector?: unknown
  ): QuoteItemView;
}

export class QuoteViewChildFactoryImpl implements QuoteViewChildFactory {
  parent: QuoteDataEntryView;

  constructor(parent: QuoteDataEntryView) {
    this.parent = parent;
  }

  getDetailView(options: QuoteDetailViewOptions): QuoteDetailView {
    return new QuoteDetailView(options);
  }

  getItemsView(options: QuoteItemsViewOptions): QuoteItemsView {
    return new QuoteItemsView(options);
  }

  getSupplierDefaultsView(options: QuoteSupplierDefaultsViewOptions): QuoteSupplierDefaultsView {
    return new QuoteSupplierDefaultsView(options);
  }

  getSupplierGlassView(options: QuoteSupplierGlassViewOptions): QuoteSupplierGlassView {
    return new QuoteSupplierGlassView(options);
  }

  getSupplierQuoteItemView(
    _contentType: number,
    options: QuoteItemViewOptions,
    _supplierSelector?: unknown
  ): QuoteItemView {
    return new QuoteItemView(options);
  }
}

//Quote view encloses the entire Quote edit and view process
// contains detail view
// will contained default settings view
// quote items list.

@customElement('wm-quotedataentryview')
export class QuoteDataEntryView extends DataEntryPageControlView {
  quoteApi: QuoteApi = getApiFactory().quote();
  supplierApi: SupplierApi = getApiFactory().supplier();
  initPromises: Promise<any>[] = [];
  ownerId: EventGetReference;
  public userProfileCache: DataCacheGeneric;
  supplierQuoteConfig?: ResultGetSupplierQuoteSettings;
  protected eventTitle: EventSnippet;
  protected blobApi = getApiFactory().blob();
  protected clientApi = getApiFactory().client();
  protected quoteViewChildFactory: QuoteViewChildFactory;
  protected detailView: QuoteDetailView;
  protected itemsView: QuoteItemsView;
  protected supplierQuoteDefaultsView: QuoteSupplierDefaultsView;
  protected readonly maxItemPageAllowed: number = 1;
  protected supplierGlassView: QuoteSupplierGlassView | null;
  private lastDeletedTabItemId?: string;
  private readonly discardChangesText = tlang`Discard Changes`;

  constructor(options: QuoteViewOptions, owner: DataEntryOwner) {
    super(owner);
    this.quoteViewChildFactory = this.getQuoteViewChildFactory();
    this._quoteContainerManager = options.quoteContainerManager;
    this.eventTitle = options.title;
    this.userProfileCache = options.userCache;
    this.ownerId = options.ownerId;

    //UI only created after building quoteContainerManager
    this.detailView = this.quoteViewChildFactory.getDetailView({
      quoteManager: this.quoteContainerManager
    });
    this.itemsView = this.quoteViewChildFactory.getItemsView({
      quoteManager: this.quoteContainerManager,
      userProfileCache: this.userProfileCache,
      eventRunQuoteItemAction: async (quoteItemContainer: QuoteItemContainer, action: number) =>
        await this.runQuoteItemAction(quoteItemContainer, action)
    });
    this.supplierQuoteDefaultsView = this.quoteViewChildFactory.getSupplierDefaultsView({
      quoteManager: this.quoteContainerManager,
      areItemsOpen: () => this.itemPagesOpenCount > 0
    });

    this.supplierGlassView = this.hasSupplierGlassView()
      ? this.quoteViewChildFactory.getSupplierGlassView({
          quoteManager: this.quoteContainerManager,
          forceReadonly: () => this.glassViewReadonly()
        })
      : null;

    this.quoteContainerManager.afterSave.push(async () => {
      this.detailView.requestUpdate();
      await this.itemsView.refreshData();
      this.requestUpdate();
    });
  }

  protected _quoteContainerManager: QuoteContainerManager;

  public get quoteContainerManager(): QuoteContainerManager {
    return this._quoteContainerManager;
  }

  public set quoteContainerManager(value: QuoteContainerManager) {
    this._quoteContainerManager = value;
  }

  public get quoteContainer(): QuoteContainer {
    return this.quoteContainerManager.container;
  }

  get quoteItemsViewPageIndex() {
    let pageindex = 0;
    this.pageControl.pages.some((page, index) => {
      const found = page.data === this.itemsView;
      if (found) pageindex = index;
      return found;
    });
    return pageindex;
  }

  protected get itemPagesOpenCount(): number {
    let count = 0;
    this._pageControl?.pages.forEach(page => {
      if (page.data instanceof QuoteItemView) {
        count++;
      }
    });
    return count;
  }

  protected get isLockedFromUse(): boolean {
    return this.quoteContainerManager.isLockedFromUse();
  }

  private get supplierName(): string {
    return this._quoteContainerManager.supplierName;
  }

  async dispose() {
    WMEventSource.getInstance().removeEventListener(WMEventSource.quote, this._eventQuote);
    await super.dispose();
    await disposeOf(this.detailView);
    await disposeOf(this.itemsView);
    //override if needed
  }

  getQuoteStateEngine(): IUserQuoteStateEngine {
    throw new DevelopmentError('Unsupported. please override;');
  }

  /**
   * inherited
   */
  public async afterConstruction() {
    WMEventSource.getInstance().addEventListener(WMEventSource.quote, this._eventQuote);
    await this.quoteContainerManager.needsQuote();

    if (!this.supplierQuoteConfig) {
      const _supplierQuoteConfig = await this.supplierApi.getSupplierQuoteConfig({
        supplierId: this.quoteContainerManager.quote.supplierId
      });
      if (_supplierQuoteConfig) {
        this.supplierQuoteConfig = _supplierQuoteConfig;
      }
    }

    if (this.supplierGlassView) this.initPromises.push(constructAsync(this.supplierGlassView));

    this.initPromises.push(constructAsync(this.supplierQuoteDefaultsView));
    await this.detailView.afterConstruction();
    await this.itemsView.afterConstruction();

    //this will create the page control
    await super.afterConstruction();
    await this.buildQuoteActionMenu();
  }

  async waitInit(): Promise<void> {
    const w = waitPatientlyLoading(() => tlang`Initializing %%quote%% data`);
    try {
      await Promise.allSettled(this.initPromises);
    } finally {
      await w.hideModal();
    }
  }

  async addNewQuoteItemFreehand(): Promise<boolean> {
    if (this.isDataReadonly()) return false;
    if (!(await this.allowPageSwitch())) return false;
    const providerType = '';
    return await this.constructAndLaunchItemView(providerType, quoteItemContentType.freehand);
  }

  async addNewQuoteItemSpecial(): Promise<boolean> {
    if (this.isDataReadonly()) return false;
    if (!(await this.allowPageSwitch())) return false;
    const providerType = '';
    return await this.constructAndLaunchItemView(providerType, quoteItemContentType.specialItem);
  }

  /**
   * inherited;
   * @returns
   */
  public async prepareForSave(): Promise<void> {
    await this.waitInit();
    if (this.isDataReadonly()) return;
    await this.supplierQuoteDefaultsView.prepareForSave();
    await this.itemsView.prepareForSave();
    await this.detailView.prepareForSave();
    await this.supplierGlassView?.prepareForSave();
  }

  async performQuoteItemsViewRefresh(): Promise<void> {
    if (this.itemPagesOpenCount == 0) {
      await this.quoteContainerManager.needsQuoteItems(true);
      await this.itemsView.refreshData();
      fireQuickSuccessToast(tlang`!!quote-item!! refreshed`);
    } else fireQuickWarningToast(tlang`Please close all open %%quote-item%% pages before refreshing this page`);
  }

  createQuoteItemSummaryPage(): PageManager {
    return {
      caption: () => tlang`!!quote-item!!`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.itemsView.invalidate();
      },
      onAfterEnter: async () => {
        let id: string | undefined = undefined;

        if (this.lastDeletedTabItemId && this.lastDeletedTabItemId != emptyGuid) {
          // if this is set, then the tab was delete (on delete event) and we need to clear it.
          id = this.lastDeletedTabItemId;
          this.lastDeletedTabItemId = undefined;
        }

        if (!id) {
          const editor = await this.findLastAvailableOpenQuoteItemView();

          if (editor) id = (editor.data as QuoteItemView).quoteItemContainer?.item.id;
        }

        if (id) this.dispatchCustom('!wm-scroll-into-view', { selector: `div[data-rowid='${id}']` });
      },
      content: () => {
        return this.itemsView.ui;
      },
      buttonMenu: () => {
        return html`
          ${this.abandonAndCloseButton()}
          <webmodule-button size="small" @click=${() => this.performQuoteItemsViewRefresh()} variant="default">
            ${tlang`Refresh List`}
          </webmodule-button>
          ${awaitableTemplate(this.validateOutOfDateQuoteItemsButton())}
        `;
      },
      pageFragment: 'quote-items',
      data: this.itemsView
    };
  }

  async validateOutOfDateQuoteItemsButton(): PromiseTemplate {
    if (!(await this.mustValidateItems())) return html``;

    return html` <webmodule-button
      size="small"
      @click=${async () => await this.validateOutOfDateQuoteItems()}
      variant="default"
    >
      ${tlang`Validate`}
    </webmodule-button>`;
  }

  /**
   *
   * @returns override this to add logic
   */
  async mustValidateItems(): Promise<boolean> {
    return false;
  }

  /**
   * override this to add logic
   */
  async validateOutOfDateQuoteItems() {
    throw new Error('Method not implemented.');
  }

  async getQuoteItemViewOptions(quoteItemContainer: QuoteItemContainer | null): Promise<QuoteItemViewOptions> {
    if (!this.quoteContainer.quote) {
      throw new DevelopmentError('quote-view, quote is null');
    }
    return {
      supplierId: this.quoteContainer.quote.supplierId,
      quoteManager: this.quoteContainerManager,
      quoteItemContainer: quoteItemContainer,
      isClientFacing: () => this.itemsView.isClientFacing
    };
  }

  /**
   * inherited;
   * @returns
   */
  public internalDataChanged(): boolean {
    return !this.abandoned && this.quoteContainerManager.quoteChanged();
  }

  /**
   * inherited
   * @returns
   */
  public getDataDictionaryName(): string {
    return '%%quote%%';
  }

  /**
   * inherited
   * @returns
   */
  public isDataReadonly(): boolean {
    return this.quoteContainerManager.isReadonly();
  }

  public getTitle(): Snippet {
    const isMulti = isMultiSupplier();
    const supplierName = this.supplierName;

    return isMulti
      ? html`
          ${this.eventTitle(this.quoteContainer.quote, this.quoteContainerManager.quoteState)}
          <br />
          <span class="subtitle">Supplier> ${supplierName}</span>
        `
      : this.eventTitle(this.quoteContainer.quote, this.quoteContainerManager.quoteState);
  }

  public async runQuoteItemAction(quoteItemContainer: QuoteItemContainer, action: number): Promise<void> {
    //add action switch
    switch (action) {
      case quoteItemAction.edit:
        await this.internalOpenQuoteItemForEdit(quoteItemContainer);
        break;
      case quoteItemAction.properties:
        await this.internalOpenQuoteItemPropertyDialog(quoteItemContainer);
        break;
      case quoteItemAction.copy:
        await this.quoteContainerManager.copyQuoteItem(quoteItemContainer);
        break;
      case quoteItemAction.delete:
        await this.internalDeleteQuoteItem(quoteItemContainer);
        break;
      default:
        fireQuickWarningToast(tlang`${action.toString()} is not supported yet`);
    }
  }

  async tabDeletedEvent(options: PageControlDeleteTabEventData): Promise<void> {
    if (options.deletedPage.data instanceof QuoteItemView) {
      options.newIndex = this.quoteItemsViewPageIndex;
    }
  }

  public async generateReportV2(_dealerReport: DealerReport): Promise<boolean> {
    throw new Error('Report Override not implemented.');
  }

  async afterShopDrawingFileCreated(_drawingFile: FileMap, _isTempFile: boolean) {
    //donothing
  }

  async itemsNeedValidation(changesAllowed = false) {
    const revalidationMsg = asMarkdown(tlang`${'ref:QuoteView-IssuedQuoteRevalidation'}
This %%quote%% is out of date due to an update by %%supplier%% **${this.quoteContainerManager.supplierName}**<br>

Please attempt to revalidate using the "Validate" button.<br>

If the %%quote%% can be revalidated, then all prices will remain as they are, even if the %%supplier%% prices have been updated. <br>

If the %%quote%% cannot be revalidated, then it must be cancelled and you must start a new %%quote%%
`);
    const validationMsg = asMarkdown(tlang`${'ref:WI221017-QuoteView-ActiveQuoteValidation'}
This %%quote%% is out of date due to an update by %%supplier%% **${this.quoteContainerManager.supplierName}**<br>

Please attempt to revalidate using the "Validate" button.<br>

After validation, please review the !!quote-item!! carefully for any unexpected price or design changes
`);
    if (await this.mustValidateItems()) {
      await information(changesAllowed ? validationMsg : revalidationMsg, tlang`Invalid !!quote-item!!`);
      return true;
    }
    return false;
  }

  async canCompleteReview(): Promise<boolean> {
    return true;
  }

  addReportMenuItems(_childEvents: MenuIconEventList) {}

  addSupplierMenuItems(_childEvents: MenuIconEventList) {}

  async produceShopDrawing() {
    throw new Error('please overrride this method');
  }

  async produceSupplierQuote() {
    throw new Error('please overrride this method');
  }

  protected includeShippingLineItem(): boolean {
    return true;
  }

  protected async doAbandoned(): Promise<void> {
    if (this.isNew()) await this.quoteContainerManager.deleteQuote();
  }

  protected glassViewReadonly(): boolean {
    //if the logic changes here, then the reaonly alert on the v6-quote-supplier-glass-view.ts must also change
    return this.itemPagesOpenCount > 0;
  }

  protected getQuoteViewChildFactory(): QuoteViewChildFactory {
    return new QuoteViewChildFactoryImpl(this);
  }

  protected hasSupplierGlassView(): boolean {
    return false;
  }

  protected isNew(): boolean {
    return this.quoteContainer.isNewQuote;
  }

  protected async quoteItemViewFactory(
    quoteItemContainer: QuoteItemContainer | null,
    providerType: string,
    contentType: number,
    supplierSelector: unknown
  ): NullPromise<QuoteItemView> {
    if (providerType !== '') {
      const options = await this.getQuoteItemViewOptions(quoteItemContainer);
      return await constructAsync(
        this.quoteViewChildFactory.getSupplierQuoteItemView(contentType, options, supplierSelector)
      );
    } else if (providerType === '') {
      //these are the default non-provider line items
      switch (contentType) {
        case quoteItemContentType.shipping:
          return await constructAsync(new ShippingLineItemView(await this.getQuoteItemViewOptions(quoteItemContainer)));
        case quoteItemContentType.freehand:
          return await constructAsync(new FreehandLineItemView(await this.getQuoteItemViewOptions(quoteItemContainer)));
        case quoteItemContentType.specialItem:
          return await constructAsync(new SpecialLineItemView(await this.getQuoteItemViewOptions(quoteItemContainer)));
        case quoteItemContentType.dealerQuotePriceAdjustment:
          return null;
        default:
          if (isContentTypeSSI(contentType)) {
            return await constructAsync(
              new SpecialLineItemView(await this.getQuoteItemViewOptions(quoteItemContainer))
            );
          }
          await showDevelopmentError(`Content Type ${contentType} is not supported`);
          break;
      }
    } else await showDevelopmentError(`Provider Type "${providerType}" is not supported`);
    return null;
  }

  protected bodyTemplate(): EventTemplate {
    this.buildQuoteActionMenu();
    return super.bodyTemplate();
  }

  protected doAddPages(_pages: PageManager[]) {
    //override;
  }

  /**
   * inherited;
   * @returns
   */
  protected createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      pages.push(this.createDetailPage());
      pages.push(this.createSupplierQuoteDefaultsPage());
      if (this.hasSupplierGlassView()) pages.push(this.createSupplierGlassPage());
      pages.push(this.createQuoteItemSummaryPage());
      this.doAddPages(pages);
      return pages;
    };

    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: undefined,
      pageInitializer: () => getInitialPageManagers(),
      events: {
        onDeleteTab: async (options: PageControlDeleteTabEventData) => await this.tabDeletedEvent(options)
      }
    };
    return new PageControl(options);
  }

  protected createSupplierQuoteDefaultsPage(): PageManager {
    return {
      caption: () => tlang`Defaults`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.waitInit();
      },
      content: () => {
        return this.supplierQuoteDefaultsView.ui;
      },
      buttonMenu: () => {
        if (this.isLockedFromUse) return html``;
        return html` ${this.abandonAndCloseButton()} ${this.supplierQuoteDefaultsView.buttonMenu()}`;
      },
      data: this.supplierQuoteDefaultsView,
      pageFragment: 'supplierDefaults'
    };
  }

  protected createSupplierGlassPage(): PageManager {
    return {
      caption: () => tlang`!!quote-igu!!`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.waitInit();
        if (!this.supplierGlassView) throw new DevelopmentError('supplierGlassView is null');

        //just display a message. we will make it readonly
        await this.checkAllFramesClosed();
      },
      content: () => {
        if (!this.supplierGlassView) throw new DevelopmentError('supplierGlassView is null');
        return this.supplierGlassView;
      },
      buttonMenu: () => {
        if (this.isLockedFromUse) return html``;
        if (!this.supplierGlassView) throw new DevelopmentError('supplierGlassView is null');
        return html` ${this.abandonAndCloseButton()} ${this.supplierGlassView.buttonMenu()}`;
      },
      data: this.supplierGlassView,
      pageFragment: 'quoteIgu'
    };
  }

  protected async checkAllFramesClosed(): Promise<boolean> {
    if (this.itemPagesOpenCount > 0) {
      fireQuickWarningToast(tlang`please close all !!quote-item!! before editing !!quote-igu!!`);
      return false;
    }
    return true;
  }

  /**
   * inherited
   * @returns
   */
  protected async internalSaveData(): Promise<boolean> {
    //this will populate the managed quote with data from the UI
    return await this.quoteContainerManager.saveQuote(isAutoSaving());
  }

  /**
   *
   * @returns inherited
   */
  protected getValidationErrors(): string[] {
    //prepare for save should always be called first
    return [
      ...this.detailView.getValidationErrors(),
      ...this.itemsView.getValidationErrors(),
      ...(this.supplierGlassView?.getValidationErrors() ?? [])
    ];
  }

  protected async quoteItemViewAlreadyOpen(): Promise<boolean> {
    const alreadyOpen = this.itemPagesOpenCount >= this.maxItemPageAllowed;
    if (alreadyOpen)
      await information(
        tlang`Please close and save the existing %%frame%%/%%special-item%% editor before opening another one`
      );
    return alreadyOpen;
  }

  protected async addNewQuoteItemFrame(frameSelector?: unknown): Promise<boolean> {
    if (this.isDataReadonly()) return false;
    if (!(await this.allowPageSwitch())) return false;
    if (!(await this.canAddItemFrame())) return false;

    await this.closeLastAvailableQuoteItemView();

    const providerType = await this.selectService();
    const quoteItemView = await this.quoteItemViewFactory(
      null,
      providerType,
      supplierQuoteItemContentType.CID_FRAM,
      frameSelector
    );
    try {
      if (quoteItemView) {
        await quoteItemView.prepareEditor();
        if (quoteItemView.readyToEdit) {
          await this.launchQuoteItemView(quoteItemView);
          return true;
        }
        return false;
      }
    } catch (e) {
      await showError(e as Error);
      return false;
    }

    return false;
  }

  protected async canAddItemFrame(): Promise<boolean> {
    return true;
  }

  protected async launchQuoteItemView(quoteItemView: QuoteItemView | null): Promise<void> {
    if (quoteItemView) {
      const page = quoteItemView.isTab() ? quoteItemView.createPageManager() : null;
      if (page) {
        page.onDelete = async (_pageIndex, page) => {
          if (page.data instanceof QuoteItemView) {
            this.lastDeletedTabItemId = (page.data as QuoteItemView).quoteItemContainer?.item.id;
          }
        };

        await this.closeLastAvailableQuoteItemView();

        if (await this.quoteItemViewAlreadyOpen()) return;
        this.pageControl.addPage(page);
      } else if (quoteItemView.hasModalEditDialog) {
        // a fallback for items that have a property dialog, but no page control.
        await quoteItemView.executeModalEditDialog();
      } else if (quoteItemView.hasPropertyDialog) {
        // a fallback for items that have a property dialog, but no page control.
        await quoteItemView.executePropertyDialog();
      } else fireQuickWarningToast(tlang`This %%quote-item%% cannot be edited`);
    }
  }

  protected async selectService(): Promise<string> {
    return currentQuoteSupplierProvider;
  }

  /**
   * update the state and save the quote. this will work even if in readonly state.
   * @param state the new quote state.
   * @returns
   */
  protected async internalRevertQuoteState(state: QuoteState): Promise<boolean> {
    this.quoteContainerManager.quote.state = state;
    return await this.performAutoSave();
  }

  protected async canCreateShopDrawingPreview(): Promise<boolean> {
    return false;
  }

  protected async onQuoteStateChanged(_state: QuoteState): Promise<void> {
    //do nothing
  }

  protected getStandardButtons() {
    const stateEngine = this.getQuoteStateEngine();
    return {
      approve: {
        caption: () =>
          //if this is approving a quote that was already reviewed and approved by supplier
          //we are bypassing accepted state, but displaying accept on the button
          this.quoteContainerManager.quote.supplierApproved ? tlang`Accept` : tlang`Approve`,
        event: async () => {
          if (await this.itemsNeedValidation()) {
            return;
          }
          const success = await this.setQuoteState(QuoteState.Approved);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.Approved);
          }
          // eslint-disable-next-line consistent-return
          return success;
        },

        disabled: stateEngine.isStateChangeBlocked(QuoteState.Approved)
      },

      review: {
        caption: () => tlang`Edit Revision`,
        event: async () => {
          if (await this.itemsNeedValidation()) {
            return;
          }

          const success = await this.setQuoteState(QuoteState.SupplierReviewPending);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.SupplierReviewPending);
          }
          // eslint-disable-next-line consistent-return
          return success;
        },

        disabled: stateEngine.isStateChangeBlocked(QuoteState.SupplierReviewPending)
      },
      reviewDone: {
        caption: () => tlang`Complete Revision`,
        event: async () => {
          if (await this.itemsNeedValidation()) {
            return;
          }
          if (!(await this.canCompleteReview())) return;
          const success = await this.setQuoteState(QuoteState.SupplierReviewed);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.SupplierReviewed);
          }
          // eslint-disable-next-line consistent-return
          return success;
        },

        disabled: stateEngine.isStateChangeBlocked(QuoteState.SupplierReviewed)
      },
      cancel: {
        caption: () => tlang`Cancel`,
        event: async () => {
          const success = await this.setQuoteState(QuoteState.Cancelled);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.Cancelled);
          }
          return success;
        },
        classList: 'btn btn-dark',
        disabled: stateEngine.isStateChangeBlocked(QuoteState.Cancelled)
      },
      issue: {
        caption: () => tlang`Issue`,
        event: async () => {
          if (await this.itemsNeedValidation(true)) {
            return;
          }

          const success = await this.setQuoteState(QuoteState.IssuePending);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.IssuePending);
          }
          // eslint-disable-next-line consistent-return
          return success;
        },
        disabled: stateEngine.isStateChangeBlocked(QuoteState.IssuePending)
      },
      activate: {
        caption: () => tlang`Make %%quote%% Active`,
        event: async () => {
          if (await this.itemsNeedValidation()) {
            return;
          }
          const success = await this.setQuoteState(QuoteState.Active);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.Active);
          }
          // eslint-disable-next-line consistent-return
          return success;
        },
        disabled: stateEngine.isStateChangeBlocked(QuoteState.Active)
      },
      accept: {
        caption: () => tlang`Accept %%quote%%`,
        event: async () => {
          if (await this.itemsNeedValidation()) {
            return;
          }

          const success = await this.setQuoteState(QuoteState.Accepted);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.Accepted);
          }
          // eslint-disable-next-line consistent-return
          return success;
        },
        classList: 'btn btn-primary',
        disabled: stateEngine.isStateChangeBlocked(QuoteState.Accepted)
      },
      reject: {
        caption: () => tlang`Reject %%quote%%`,
        event: async () => {
          const success = await this.setQuoteState(QuoteState.Rejected);
          if (success) {
            await this.onQuoteStateChanged(QuoteState.Rejected);
          }
          return success;
        },
        classList: 'btn btn-dark',
        disabled: stateEngine.isStateChangeBlocked(QuoteState.Rejected)
      },
      addFrame: this.buildFrameButtons().map(x => {
        const btn: MenuIconAction = {
          event: async () => await this.addNewQuoteItemFrame(x ?? undefined),
          caption: () => (x ? lang(x.caption) : tlang`Add %%frame%%`),
          disabled: this.isDataReadonly()
        };
        return btn;
      }),
      addFreehand: {
        caption: () => tlang`Add %%freehand%%`,
        event: async () => await this.addNewQuoteItemFreehand(),
        disabled: this.isDataReadonly()
      },
      addSpecialItem: {
        caption: () => tlang`Request %%special-item%%`,
        event: async () => await this.addNewQuoteItemSpecial(),
        disabled: this.isDataReadonly()
      },
      save: {
        event: async () => {
          if (this.isDataReadonly()) return false;
          if (await this.saveActiveQuoteItem()) return await this.performAutoSave();
          else return false;
        },
        caption: () => tlang`Save`,
        disabled: this.isDataReadonly()
      },
      previewShopDrawing: {
        caption: () => tlang`!!shopdrawing!!`,
        event: async () => {
          if (
            await AskConfirmation(
              tlang`This is not a final Drawing. Continue?`,
              confirmationButtons[ConfirmationButtonType.yesNo]
            )
          ) {
            if (await this.mustValidateItems()) {
              //we cannot produce a shop drawing if validations are required
              await information(
                tlang`${'ref:shopdrawings-revalidation-required'}Shop drawings cannot be produced until revalidation of the %%quote%% is performed`,
                tlang`Validation required`
              );
              return false;
            }
            if (this.quoteContainerManager.isV6) {
              await this.produceShopDrawing();
              fireQuickInformationToast(tlang`You will be emailed a link to the document when it is ready`);
            } else throw new DevelopmentError('createSupplierQuote not implemented');

            return false;
          }
          return false;
        }
      },
      createSupplierQuote: {
        caption: () => tlang`Push %%supplier%% %%quote%% to V6`,
        event: async () => {
          if (await this.mustValidateItems()) {
            //we cannot produce a shop drawing if validations are required
            await information(
              tlang`${'ref:supplierquote-revalidation-required'}V6 Quote cannot be produced until revalidation of the %%quote%% is performed`,
              tlang`Validation required`
            );
            return false;
          }
          if (
            await AskConfirmation(
              tlang`This will create a V6 quote in the supplier system. It is not linked to this %%quote%%
              and will be created using the current quote configuration including any warnings and errors.
              Continue?
                `,
              confirmationButtons[ConfirmationButtonType.yesNo]
            )
          ) {
            if (this.quoteContainerManager.isV6) {
              await this.produceSupplierQuote();
            } else throw new DevelopmentError('createSupplierQuote not implemented');

            return false;
          }
          return false;
        }
      }
    };
  }

  protected buildFrameButtons(): (ButtonCaptionProvider | null)[] {
    return [null];
  }

  protected customButtons(_state: QuoteState): MenuIconOption[] {
    return [];
  }

  /**
   * Tries to find an open QuoteItemView for the given quote item.
   * @param quoteItemContainer
   * @returns
   */
  protected findIfPageAvailable(quoteItemContainer: QuoteItemContainer): PageManager | undefined {
    return this.pageControl.pages.find(page => {
      if (page.data instanceof QuoteItemView) {
        if ((page.data as QuoteItemView).quoteItemContainer?.item.id === quoteItemContainer.item.id) {
          return true;
        }
      }
      return false;
    });
  }

  /**
   * Closes the last available QuoteItemView in the page control.
   * If a QuoteItemView is found, it is closed by calling the 'closePage' method of the page control.
   *
   * @returns {Promise<void>} - A Promise that resolves when the QuoteItemView is closed, or if no QuoteItemView is found.
   */
  protected async closeLastAvailableQuoteItemView(): Promise<void> {
    const frameEditor = this.pageControl.pages.reduceRight(
      (acc: PageManager | undefined, page) => (page.data instanceof QuoteItemView ? page : acc),
      undefined
    );

    if (frameEditor) {
      const id = (frameEditor.data as QuoteItemView).quoteItemContainer?.item.id;
      await this.pageControl.closePage(frameEditor);

      if (id) this.dispatchCustom('!wm-scroll-into-view', { selector: `div[data-rowid='${id}']` });
    }
  }

  /**
   * Find the last available Open QuoteItemView in the page control.
   */
  protected async findLastAvailableOpenQuoteItemView(): Promise<PageManager | undefined> {
    const frameEditor = this.pageControl.pages.reduceRight(
      (acc: PageManager | undefined, page) => (page.data instanceof QuoteItemView ? page : acc),
      undefined
    );

    if (frameEditor) {
      return frameEditor;
    }
    return undefined;
  }

  protected async closePageIfAvailable(quoteItemContainer: QuoteItemContainer): Promise<boolean> {
    const foundPage = this.findIfPageAvailable(quoteItemContainer);
    if (foundPage) {
      await this.pageControl.closePage(foundPage);

      if (quoteItemContainer)
        this.dispatchCustom('!wm-scroll-into-view', { selector: `div[data-rowid='${quoteItemContainer.item.id}']` });

      return true;
    }
    return false;
  }

  protected async internalDeleteQuoteItem(quoteItemContainer: QuoteItemContainer): Promise<void> {
    const qm = this.quoteContainerManager;
    await this.closePageIfAvailable(quoteItemContainer);

    //TODO when deleting an item, we are going to need to hook in the SSI processing
    if (await qm.deleteQuoteItem(quoteItemContainer)) {
      fireQuickSuccessToast(tlang`%%quote-item%% has been deleted`);
    } else {
      fireQuickErrorToast(tlang`Unable to delete the selected %%quote-item%%`);
    }
  }

  protected async constructAndLaunchItemView(providerType: string, itemContentType: number) {
    const quoteItemView = await this.quoteItemViewFactory(null, providerType, itemContentType, undefined);
    try {
      if (quoteItemView) {
        await quoteItemView.prepareEditor();
        if (quoteItemView.readyToEdit) {
          await this.launchQuoteItemView(quoteItemView);
          return true;
        }
        return false;
      }
    } catch (e) {
      await showError(e as Error);
      return false;
    }
    return false;
  }

  protected async internalOpenQuoteItemPropertyDialog(quoteItemContainer: QuoteItemContainer): Promise<void> {
    if (await this.focusIfPageAvailable(quoteItemContainer)) {
      fireQuickWarningToast(tlang`Please save and close the %%quote-item%% before editing its properties`);
      return;
    }

    const quoteItemView = await this.internalCreateQuoteItemView(quoteItemContainer, false);
    if (quoteItemView) {
      if (quoteItemView.hasPropertyDialog) {
        // a fallback for items that have a property dialog, but no page control.
        await quoteItemView.executePropertyDialog();
      }
    }
  }

  private _eventQuote = (_data: unknown, _eventName: string) => {
    const data = _data as SSEQuote;
    const state = this.quoteContainerManager.quote.state;
    if (data.id !== this.quoteContainerManager.quote.id) return;
    const resourceUpdateRequired =
      (state === QuoteState.IssuePending && data.state !== QuoteState.IssuePending) ||
      (data.recordVersion !== this.quoteContainerManager.quote.recordVersion &&
        this.quoteContainerManager.isReadonly());
    if (resourceUpdateRequired)
      this.ui.dispatchEvent(
        new CustomEvent('resource-changed', {
          bubbles: true,
          composed: true,
          detail: {}
        })
      );
  };

  private abandonAndCloseButton() {
    if (this.isLockedFromUse || this.isDataReadonly()) return html``;
    return html` <webmodule-button @click=${() => this.abandonAndClose()} variant="default" size="small">
      ${this.discardChangesText}
    </webmodule-button>`;
  }

  /**
   * this is used to change state, and ensures that anything is saved that needs to be
   * saved on an active item that might be open before saving the quote
   * @param state
   * @returns
   */
  private async setQuoteState(state: QuoteState): Promise<boolean> {
    try {
      if (await this.saveActiveQuoteItem()) {
        await this.prepareForSave();
        return await this.quoteContainerManager.quoteStateChange(state);
        //return await this.internalSetQuoteState(state);
      } else return false;
    } finally {
      this.requestUpdateAndPropagate();
    }
  }

  private buildQuoteActionMenu() {
    if (!this._pageControl) return;
    const menuIcons: MenuIconOption[] = [];
    const buttons = this.getStandardButtons();

    const reports: MenuIconOption = {
      caption: () => tlang`Reports`,
      childEvents: []
    };

    const supplierMenu: MenuIconOption = {
      caption: () => tlang`%%supplier%%`,
      childEvents: []
    };
    if (reports.childEvents) this.addReportMenuItems(reports.childEvents);
    if (supplierMenu.childEvents) this.addSupplierMenuItems(supplierMenu.childEvents);

    const status: MenuIconOption = {
      caption: () => tlang`Status`,
      childEvents: []
    };
    const frameButton: MenuIconOption =
      buttons.addFrame.length === 1
        ? buttons.addFrame[0]
        : {
            caption: () => tlang`Add %%frame%%`,
            disabled: this.isDataReadonly(),
            childEvents: buttons.addFrame.filter(x => !x.disabled)
          };
    const pushValidStatusMenu = (...args) => {
      status.childEvents = args.filter(x => !x.disabled) ?? [];
      if (status.childEvents?.length > 0) menuIcons.push(status);
    };
    const pushButton = (...args) => {
      if (this.isDataReadonly()) return;
      args.forEach(x => {
        if (x !== undefined && x !== null) menuIcons.push(x);
      });
    };

    if (!this.isLockedFromUse)
      switch (this.quoteContainerManager.quote.state) {
        case QuoteState.Draft:
          pushValidStatusMenu(buttons.activate, buttons.cancel);
          pushButton(frameButton, buttons.addFreehand);
          break;
        case QuoteState.Active:
          pushValidStatusMenu(buttons.issue, buttons.cancel);
          pushButton(frameButton, buttons.addFreehand);

          if (this.supplierQuoteConfig?.allowCustomItemRequest) pushButton(buttons.addSpecialItem);

          pushButton(...this.customButtons(QuoteState.Active));

          break;
        case QuoteState.Issued:
          pushValidStatusMenu(buttons.approve, buttons.accept, buttons.reject);
          break;
        case QuoteState.Accepted:
        case QuoteState.Accepted_AssignedToReviewer:
          pushValidStatusMenu(buttons.approve, buttons.review, buttons.reject);
          break;
        case QuoteState.SupplierReviewPending:
          pushValidStatusMenu(buttons.reviewDone);
          pushButton(frameButton, buttons.addFreehand);

          if (this.supplierQuoteConfig?.allowCustomItemRequest) pushButton(buttons.addSpecialItem);

          pushButton(...this.customButtons(QuoteState.SupplierReviewPending));

          break;
        case QuoteState.SupplierReviewed:
          pushValidStatusMenu(buttons.issue, buttons.cancel);
          break;
      }

    if (reports.childEvents && reports.childEvents.length > 0) menuIcons.push(reports);
    if (isSupplierAgent() || isDebugMode())
      if (supplierMenu.childEvents && supplierMenu.childEvents.length > 0) menuIcons.push(supplierMenu);

    if (!isAutoSaving()) menuIcons.push(buttons.save);

    this.pageControl.setMenuIcons(menuIcons);
  }

  private async saveActiveQuoteItem(): Promise<boolean> {
    //if we are readonly, we never save anything, even if the UI accidentally things we should.
    if (!this.quoteContainerManager.isReadonly() && this.pageControl.activePage?.data instanceof QuoteItemView) {
      const view = this.pageControl.activePage?.data as QuoteItemView;
      if (await view.dataNeedsSaving()) return await view.saveQuoteItem();
      else return true;
    }
    return true;
  }

  private async internalCreateQuoteItemView(
    quoteItemContainer: QuoteItemContainer,
    needsPrepareEditor: boolean
  ): NullPromise<QuoteItemView> {
    const providerType = quoteItemContainer.item.serviceProvider;
    const quoteItemView = await this.quoteItemViewFactory(
      quoteItemContainer,
      providerType,
      quoteItemContainer.item.quoteItemContentType,
      undefined
    );
    if (quoteItemView)
      try {
        if (needsPrepareEditor) await quoteItemView.prepareEditor();
        if (!needsPrepareEditor || quoteItemView.readyToEdit) return quoteItemView;
      } catch {
        return null;
      }
    return null;
  }

  /**
   * this is the generic item edit launch routine that checks a number of things.
   * first it checks if there is a page tab open for this item, and focus it if available
   * next check is that we only allow a certain count of tabs to be open at any time
   * to restrict resources and focus work. if this limit is going to exceed we cancel
   * if the item is not a page control item, we check and lauch its modal editor if it has one,
   * finally if not, launch the property editor if it has one.
   * @param quoteItemContainer the item to open for editing
   * @returns
   */
  private async internalOpenQuoteItemForEdit(quoteItemContainer: QuoteItemContainer): Promise<void> {
    if (await this.focusIfPageAvailable(quoteItemContainer)) return;
    if (
      (isFrame(quoteItemContainer.item) || isSpecialItem(quoteItemContainer.item)) &&
      (await this.quoteItemViewAlreadyOpen())
    )
      return;

    if (quoteItemContainer.price.supplierPriceAdjustment != 0) {
      const message = tlang`${'ref:WI221950-QuoteView-DiscardSupplierPriceAdjustmentOnEdit'}
        If you make changes that affect the price on this item the supplier price adjustment will be discarded.
        You will then need to reactivate the associated support ticket to request a supplier review.`;
      if (!(await AskConfirmation(message, buttonsContinueCancel(), undefined, tlang`Supplier Price Adjustment`)))
        return;
    }

    const quoteItemView = await this.internalCreateQuoteItemView(quoteItemContainer, true);
    await this.launchQuoteItemView(quoteItemView);
  }

  /**
   * before we create and open a page item for editing something, we always want to test if it already exists
   * and focus it instead
   * @param quoteItemContainer
   * @returns
   */
  private async focusIfPageAvailable(quoteItemContainer: QuoteItemContainer): Promise<boolean> {
    const foundPage = this.findIfPageAvailable(quoteItemContainer);
    if (foundPage) {
      await this.pageControl.setActivePage(foundPage);
      return true;
    }
    return false;
  }

  private createDetailPage(): PageManager {
    return {
      caption: () => tlang`Information`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.detailView.loadOrRefresh();
      },
      content: () => {
        return this.detailView.ui;
      },
      buttonMenu: () => {
        if (this.isLockedFromUse) return html``;
        return html` ${this.abandonAndCloseButton()} ${this.detailView.buttonMenu()}`;
      },
      data: this.detailView,
      pageFragment: 'details'
    };
  }
}

@customElement('wm-quoteview')
export class QuoteView extends ModalViewBase {
  view: QuoteDataEntryView | null = null;
  options: QuoteViewOptions;

  constructor(options: QuoteViewOptions) {
    super();
    this.options = options;
  }

  /**
   * inherited
   * @returns
   */
  protected get modalSize() {
    return 'modal-fullscreen';
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }

  async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
  }

  /**
   * inherited
   * @returns
   */
  protected getTitle(): Snippet {
    return this.view?.getTitle() ?? '';
  }

  protected createView(): QuoteDataEntryView {
    return new QuoteDataEntryView(this.options, this);
  }

  protected bodyTemplate(): TemplateResult {
    return html`${this.view?.ui}`;
  }
}
