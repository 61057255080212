import { html, TemplateResult } from 'lit';

export function getToolTip(
  toolTip: string | TemplateResult,
  placement: 'top' | 'bottom' | 'left' | 'right' = 'top'
): TemplateResult {
  return typeof toolTip === 'string'
    ? html`
        <webmodule-tooltip content="${toolTip}" hoist placement=${placement}>
          <webmodule-icon library="fa" name="far-circle-question" class="text-primary"></webmodule-icon>
        </webmodule-tooltip>
      `
    : html`
        <webmodule-tooltip hoist placement=${placement}>
          <webmodule-icon library="fa" name="far-circle-question" class="text-primary"></webmodule-icon>
          <div slot="content">${toolTip}</div>
        </webmodule-tooltip>
      `;
}
